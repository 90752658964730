<template>
  <div class="navbar">
    <div id="page-title" class="container">
      <div class="navbar-brand">
        <div class="navbar-item">
          <b-icon :icon="headline.icon" size="is-medium"></b-icon>
          <span class="headline">{{ headline.headline }} </span>
          <span v-if="headline.subheadline" class="sub-headline"
            >&raquo; {{ headline.subheadline }}
          </span>
        </div>
      </div>
      <div id="navbarMenu" class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item" v-if="headline.search">
            <div class="control has-icons-right">
              <input
                class="input"
                placeholder="Suche"
                v-model="searchphrase"
                v-on:keyup.enter="search()"
              />
              <span class="icon is-right" @click="search()" id="searchbutton">
                <i class="icm icm-search"></i>
              </span>
            </div>
          </div>
          <div class="navbar-item" v-if="headline.add">
            <a
              :to="headline.add"
              @click="$parent.add()"
              type="is-link"
              class="navbar-link is-arrowless"
              ><b-icon icon="plus1"></b-icon
            ></a>
          </div>
          <div class="navbar-item" v-if="headline.submenu.length >= 1">
            <b-dropdown position="is-bottom-left" append-to-body aria-role="menu" trap-focus>
              <a slot="trigger"><b-icon icon="dots-three-vertical"></b-icon></a>

              <b-dropdown-item
                v-for="item in headline.submenu"
                v-bind:key="item.name"
                
                class="navbar-item"
                @click="submenu(item.link)"
                >{{ item.name }}</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Headline",
  data: function() {
    return {
      headline: this.$store.getters.headline,
      searchphrase: this.$parent.searchphrase
    }
  },

  methods: {
    search() {
      //console.log('Suche: ' + this.searchphrase)
      //console.log(this.$parent)
      this.$parent.searchphrase = this.searchphrase
      this.$parent.search()
    },
    submenu(link) {
      this.$emit("submenuclicked", link)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
div#page-title {
  border-bottom: 1px solid #999999;
  margin-bottom: 20px;
  vertical-align: bottom;
}

div#page-title div.navbar-item {
  font-size: 28px;
  color: #234d98;
  padding-bottom: 0px !important;
  font-family: UbuC, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

div#page-title div.navbar-brand div.navbar-item span.headline {
  padding-left: 15px;
}

div#page-title div.navbar-brand div.navbar-item span.sub-headline {
  padding-left: 8px;
}

div#page-title div.navbar-end div.navbar-item.has-dropdown div.navbar-dropdown {
  left: unset !important;
  right: 0px;
}

div#page-title #searchbutton {
  pointer-events: all !important;
  cursor: pointer !important;
}
</style>
