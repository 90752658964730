<template>
  <b-table :data="data" :striped="true" :hoverable="true" :mobile-cards="true"
   :row-class="(row, index) => (row.active == 1) ? 'green' : 'red'"
  >
     <b-table-column v-slot="props"  field="id" label="Id"  cell-class="status">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column v-slot="props"  field="id" label="Name"> {{ props.row.lastname }}, {{ props.row.firstname }} </b-table-column>

      <b-table-column v-slot="props"  field="ts" label="E-Mail">
        {{ props.row.email }}
      </b-table-column>

      <b-table-column v-slot="props"  field="ts" label="Rechte" >
      <span v-html="translatePerm(props.row.permissions)"></span> </b-table-column>



      <b-table-column v-slot="props"  label="A" class="iconlink" field="link1" header-class="iconlink">
        <a :href="`/users/view/${props.row.id}`" title="Ansehen" @click.prevent="viewUser(props.row.id)"
          ><b-icon icon="find_in_page"></b-icon
        ></a>
      </b-table-column>

      <b-table-column v-slot="props"  label="B" class="iconlink" field="link2" header-class="iconlink">
        <a :href="`/users/edit/${props.row.id}`" title="Bearbeiten" @click.prevent="editUser(props.row.id)"
          ><b-icon icon="square-edit-outline"></b-icon
        ></a>
      </b-table-column>

      <b-table-column v-slot="props"  label="L" class="iconlink" field="link3" header-class="iconlink">
        <a @click.prevent="deleteUser(props.row.id, props.row.firstname + ' ' + props.row.lastname)" title="Löschen"
          ><b-icon icon="delete_forever"></b-icon
        ></a>
      </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'userList',
  data() {
    return {
      error: '',
      loading: {}
    }
  },
  computed: {
    data: function() {
      return this.$parent.data
    }
  },
  mounted: function() {
    this.$store.commit('setHeadline', {
      subheadline: 'Übersicht',
      add: '/users/add',
      search: null,
      submenu: []
    })
    
    this.$parent.userList()
  },
  methods: {
    deleteUser: function(id, name) {
      this.$parent.deleteUser(id, name)
    },
    deleteUser2: function(id) {
      this.$parent.deleteUser(id)
    },

    viewUser: function(id) {
      this.$parent.viewUser(id)
    },
    editUser: function(id) {
      this.$parent.editUser(id)
    },
    translatePerm: function(userPermissions) {
      
      if(typeof userPermissions == 'string')
      {
        userPermissions = JSON.parse(userPermissions)
      }

      var permList = []
      var color = 'icon-grey'
      var path = ''
      var routes = this.$router.options.routes;
      for (var i = 0; i < routes.length; i++) {
        if (routes[i].meta.menu != 'main') {
          continue
        }
        color = 'icon-grey'
        path = routes[i].path.substr(1).replace('*', '')
        if (typeof userPermissions[path] != 'undefined' && userPermissions[path] == 1) {
          color = 'icon-green'
        }

        permList.push(
          '<i class="icm icm-' +
            routes[i].meta.icon +
            ' ' +
            color +
            '" title="' +
            routes[i].meta.name +
            '"></i>'
        )
      }

      return permList.join(' &nbsp; ')
    }
  }
}
</script>
