<script>
import { Line, mixins } from "vue-chartjs"
const { reactiveProp } = mixins

export default {
  name: "Linechart",
  mixins: [reactiveProp],
  extends: Line,
  props: ["chartData", "options", "updated"],
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    updated: function() {
      
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>

<style></style>
