<template>
  <div>
    <ex4-headline @submenuclicked="subMenuHandler" />
    <transition name="contenttrans" mode="out-in" appear>
      <router-view />
    </transition>
  </div>
</template>

<script>
import Ex4Headline from "../_helpers/headline.vue"

const InvoiceList = (resolve) => {
  require.ensure(
    ["./list.vue"],
    () => {
      resolve(require("./list.vue"))
    },
    "invoices"
  )
}

const InvoiceView = (resolve) => {
  require.ensure(
    ["./view.vue"],
    () => {
      resolve(require("./view.vue"))
    },
    "invoices"
  )
}

const InvoiceAdd = (resolve) => {
  require.ensure(
    ["./add.vue"],
    () => {
      resolve(require("./add.vue"))
    },
    "invoices"
  )
}

const InvoiceAddConsignmentInvoice = (resolve) => {
  require.ensure(
    ["./addconsignmentinvoice.vue"],
    () => {
      resolve(require("./addconsignmentinvoice.vue"))
    },
    "invoices"
  )
}

const InvoiceCsvExport = (resolve) => {
  require.ensure(
    ["./csvexport.vue"],
    () => {
      resolve(require("./csvexport.vue"))
    },
    "invoices"
  )
}

export const invoiceRoutes = [
  {
    path: "",
    redirect: "index"
  },
  {
    path: "index",
    name: "invoiceList",
    component: InvoiceList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "view/:id",
    name: "invoiceView",
    component: InvoiceView,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "add",
    name: "invoiceAdd",
    component: InvoiceAdd,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "addconsignmentinvoice",
    name: "invoiceAddConsignmentInvoice",
    component: InvoiceAddConsignmentInvoice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "csvexport",
    name: "invoiceCsvExport",
    component: InvoiceCsvExport,
    meta: {
      requiresAuth: true
    }
  }
]

export default {
  name: "Invoices",
  components: {
    Ex4Headline,
    InvoiceList,
    InvoiceView,
    InvoiceAdd,
    InvoiceAddConsignmentInvoice,
    InvoiceCsvExport
  },
  data() {
    return {
      data: [],
      error: "",
      subtitle: ""
    }
  },
  mounted: function () {
    document.querySelector('title').innerHTML = 'Rechnungen | SG Fine Wine'
    this.$store.commit("setHeadline", {
      headline: "Rechnungen",
      search: "search",
      add: "/invoices/add",
      submenu: this.getSubMenu(),
      icon: "document-check"
    })
  },
  methods: {
    getSubMenu: function () {
      return [{ name: "Excel-Liste Rechnungen", link: "excelexport" }]
    },
    search: function () {
      this.$children[1].search()
    },

    add: function () {
      this.$router
        .push({
          path: "/invoices/add"
        })
        .catch((err) => {})
    },
    addconsignmentinvoice: function () {},
    subMenuHandler(link) {
      if (link == "addconsignmentinvoice") {
        this.$router
          .push({
            path: "/invoices/addconsignmentinvoice"
          })
          .catch((err) => {})
      } else if (link == "excelexport") {
        this.getExcelExport()
      } else if (link == "csvexport") {
        //console.log("yeah")
        this.$router
          .push({
            path: "/invoices/csvexport"
          })
          .catch((err) => {
            //console.log("fehler")
            //console.log(err)
          })
        //console.log("Fertsch")
      }
    },
    getExcelExport: function () {
      var url =
        this.$store.getters.config.page.apiBaseUrl + "/invoices/getexcelexport/"
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/xlsx" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = "rechnungen" + ".xlsx"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>