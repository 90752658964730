<template>
  <div>
    <ex4-headline />
    <transition name="contenttrans" mode="out-in" appear>
      <router-view />
    </transition>
  </div>
</template>

<script>
import Ex4Headline from "../_helpers/headline.vue"

const PagesList = (resolve) => {
  require.ensure(
    ["./list.vue"],
    () => {
      resolve(require("./list.vue"))
    },
    "pages"
  )
}

const PagesView = (resolve) => {
  require.ensure(
    ["./view.vue"],
    () => {
      resolve(require("./view.vue"))
    },
    "pages"
  )
}

const PagesForm = (resolve) => {
  require.ensure(
    ["./view.vue"],
    () => {
      resolve(require("./form.vue"))
    },
    "pages"
  )
}

export const pagesRoutes = [
  {
    path: "",
    redirect: "index"
  },
  {
    path: "index",
    name: "pagesList",
    component: PagesList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "view/:id",
    name: "pagesView",
    component: PagesView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "add",
    name: "pagesAdd",
    component: PagesForm,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "edit/:id",
    name: "pagesEdit",
    component: PagesForm,
    meta: {
      requiresAuth: true
    }
  }
]

export default {
  name: "Pages",
  components: {
    PagesList,
    PagesForm,
    PagesView,
    Ex4Headline
  },
  data() {
    return {
      searchphrase: ""
    }
  },

  created: function () {
    document.querySelector('title').innerHTML = 'Seiten und FAQ | SG Fine Wine'
    this.$store.commit("setHeadline", {
      headline: "Seiten und FAQ",
      subheadline: '',
      search: false,
      add: "/pages/add",
      submenu: [],
      icon: "paragraph"
    })
  },
  methods: {
    search: function() {
      var t = this
      if (t.$router.currentRoute.fullPath != "/pages/index") {
        t.$router.push("/pages/index")
      } else {
        t.$children[1].search()
      }
    },
    add: function() {
      this.$router
        .push({
          path: "/pages/add"
        })
        .catch((err) => {})
    },
    async pageSave(formdata) {
      this.isLoading = true
      var t = this
      //console.log(t.data)
      t.$http
        .post('/pages/save', { page: formdata })
        .then(function(resp) {
          t.isLoading = false
          t.$router.push('/pages/index');
         
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.error = error.response.data.message
          } else {
            t.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
          }
          t.isLoading = false
          return false
        })
    },
     pageDelete: function(id, name) {
      this.$buefy.dialog.confirm({
        title: 'Bitte bestätigen!',
        message: 'Wollen Sie Datensatz #' + id + ' "' + name + '" wirklich löschen?',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.pageDelete2(id)
      })
    },
    pageDelete2: function(id) {
      //console.log('delete '+id);
      var t = this
      t.$http
        .post('/pages/delete/' + id)
        .then(function(resp) {
          if(t.$router.currentRoute.fullPath == '/pages/index')
          {
            //console.log('Seite ist /pages/index')
            t.$children[1].pagesList(resp.data.data)
          }
          else
          {
            //console.log('Seite ist NICHT/pages/index')
            t.$router.push('/pages/index');
          }
          
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          //console.log(error)
          if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
            t.$buefy.toast.open({
              message: error.response.data.message,
              type: 'is-danger',
              duration: 5000
            })
          } else {
            t.$buefy.toast.open({
              message: 'Server (API) nicht erreichbar. Bitte versuchen Sie es später erneut.',
              type: 'is-danger',
              duration: 5000
            })
          }
        })
    },
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
