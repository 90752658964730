import Vue from "vue"
import Vuex from "vuex"
import Axios from "../plugins/axios.js"

Vue.use(Vuex)
const hnmStorePlugin = (store) => {
  // Daten aus Token dekodieren
  ;(store.parseJwToken = function(jwToken) {
    ////console.log('Funktion parseToken läuft')
    ////console.log(jwToken);
    var payload = jwToken.trim().split(".", 3)
    ////console.log(payload);
    //Base64-Decode
    if (typeof payload[1] != "undefined" && payload.length == 3) {
      ////console.log('Base64 decode');
      payload = window.atob(payload[1])
      ////console.log(payload)
    }
    //JSON parse
    if (payload.substring(0, 2) == '{"') {
      //console.log('JSON parse');
      payload = JSON.parse(payload)
      //console.log(payload)
    }
    if (
      typeof payload.user == "object" &&
      typeof payload.user.permissions == "object"
    ) {
      return payload
    }
    return false
  }),
    (store.prepareData = function(jwToken, payload) {
      return {
        user: payload.user,
        permissions: payload.user.permissions,
        jwToken: {
          token: jwToken,
          expiresAt: payload.exp,
          refreshAfter: payload.refreshAfter,
          refreshRequested: false,
          tsDiff: payload.tsDiff
        }
      }
    })
}

export default new Vuex.Store({
  state: {
    facility: 'gr',
    created: false,
    status: "",
    jwToken: {
      refreshRequested: false,
      token: false,
      expiresAt: false,
      refreshAfter: false,
      tsDiff: 0
    },
    user: {},
    permissions: {},
    config: {},
    modal1: {},
    modal2: {},
    originalUrl: "/dashboard",
    headline: {
      headline: null,
      subheadline: null,
      submenu: [],
      search: null,
      icon: null
    }
  },
  plugins: [hnmStorePlugin],
  mutations: {
    created(state) {
      state.created = true
    },
    auth_request(state) {
      state.created = true
      state.status = "loading"
    },
    auth_success(state, data) {
      state.created = true
      state.status = "success"
      state.jwToken = data.jwToken
      state.user = data.user
      state.permissions = data.user.permissions
      window.localStorage.setItem("jwToken", data.jwToken.token)
    },
    auth_error(state) {
      state.created = true
      state.status = "error"
      state.jwToken = false
      state.user = {}
      state.permissions = {}
      window.localStorage.removeItem("jwToken")
    },
    logout(state) {
      state.created = true
      state.status = "logout"
      state.jwToken = false
      state.user = {}
      state.permissions = {}
      window.localStorage.removeItem("jwToken")
    },
    token_refresh_requested(state, status) {
      state.jwToken.refreshRequested = status
    },
    set_new_token(state, jwToken) {
      state.jwToken = jwToken
      window.localStorage.setItem("jwToken", jwToken.token)
    },
    setExtranetConfig(state, config) {
      state.config = config
    },
    setOriginalUrl(state, url) {
      if (!state.config.urlsWithoutLogin.includes(url)) {
        state.originalUrl = url
      }
    },
    setHeadline(state, headline) {
      state.headline = Object.assign(state.headline, headline)
    },
    setFacility(state, facility) {

      state.facility = facility
      window.localStorage.setItem('facility', facility);
    }
  },
  actions: {
    login({ commit }, user) {
      //console.log('Store Login started')
      return new Promise((resolve, reject) => {
        commit("auth_request")
        Axios({
          url: "/login",
          data: user,
          method: "POST"
        })
          .then((resp) => {
            //console.log('Store: Token bekommen')
            var payload = this.parseJwToken(resp.data.token)
            //console.log(payload)
            if (
              typeof payload.user == "object" &&
              typeof payload.user.permissions == "object"
            ) {
              //Axios.defaults.headers.common['Authorization'] = 'Bearer' + resp.data.token
              commit("auth_success", this.prepareData(resp.data.token, payload))
              //console.log('commit fertig');
            } else {
              //console.log('Fehler aufgetreten 1');
              commit("auth_error")
            }
            resolve(resp)
          })
          .catch((err) => {
            
            //console.log(err);
            commit("auth_error")
            
            reject(err)
          })
      })
    },
    logout({ commit }, user) {
      commit("logout")
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.jwToken.token,
    authStatus: (state) => state.status,
    jwtToken: (state) => state.jwToken,
    config: (state) => state.config,
    headline: (state) => state.headline,
    user: (state) => state.user,
    permissions: (state) => state.permissions,
    modal1: (state) => state.modal1,
    modal2: (state) => state.modal2,
    created: (state) => state.created,
    originalUrl: (state) => state.originalUrl,
    facility: (state) => state.facility
  }
})
