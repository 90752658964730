<template>
  <div>
    <ex4-headline />
    <transition name="contenttrans" mode="out-in" appear>
      <section>
        <b-tabs
          type="is-boxed"
          @input="changeTab"
          v-model="currentTab"
          destroy-on-hide
        >
          <template v-for="tab in tabs" >
            <b-tab-item  :key="tab.key" :value="tab.key" :label="tab.name">
            </b-tab-item>
          </template>
        </b-tabs>
        <router-view />
      </section>
    </transition>
  </div>
</template>

<script>
import Ex4Headline from "../_helpers/headline.vue"


const toolsTracking = (resolve) => {
  require.ensure(
    ["./upstracking.vue"],
    () => {
      resolve(require("./upstracking.vue"))
    },
    "tools"
  )
}

const toolsListing = (resolve) => {
  require.ensure(
    ["./upslist.vue"],
    () => {
      resolve(require("./upslist.vue"))
    },
    "tools"
  )
}

export const toolsRoutes = [
  {
    path: "",
    redirect: "upstracking"
  },
  {
    path: "upstracking",
    name: "toolsUpstracking",
    component: toolsTracking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "upslisting",
    name: "upslisting",
    component: toolsListing,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: "upstracking/:id",
    name: "toolsUpsatracking",
    component: toolsTracking,
    meta: {
      requiresAuth: true
    }
  }
 
]

export default {
  name: "Tools",
  components: {
    toolsTracking,
    toolsListing,
    Ex4Headline
  },
  data() {
    return {
      
      tabs: [
        { key: "upstracking", name: "UPS-Tracking" },
        { key: "upslisting", name: "UPS-Paketliste" },
      ]
    }
  },

  created: function() {
    this.$store.commit("setHeadline", {
      headline: "UPS",
      subheadline: "",
      search: false,
      add: false,
      submenu: [],
      icon: "ups"
    })

    var currentRoute = this.$router.currentRoute.fullPath.split("/")
    if (currentRoute[2].length > 2) {
      for(var i = 0; i <= this.tabs.length; i++)
      {
        if(this.tabs[i].key == currentRoute[2])
        {
          this.currentTab = currentRoute[2];
          break;
        }   
      }
    }
  },
  methods: {
    changeTab(e) {
      
      this.$router
        .push({
          path: "/tools/" + e
        })
        .catch((err) => {console.log('fehler')})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
