import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/components/dashboard/index.vue'
import Customers from '@/components/customers/index.vue'
import Consignments, {
  consignmentRoutes
} from '@/components/consignments/index.vue'
import Stocks, {
  stockRoutes
} from '@/components/stocks/index.vue'

import Invoices, {
  invoiceRoutes
} from '@/components/invoices/index.vue'
import Pages, {
  pagesRoutes
} from '@/components/pages/index.vue'
import Magazine, {
  magazineRoutes
} from '@/components/magazine/index.vue'
import Tools, {
  toolsRoutes
} from '@/components/tools/index.vue'
import Data, {
  dataRoutes
} from '@/components/data/index.vue'
import Shop, { shopRoutes } from "@/components/shop/index.vue"
import Settings from '@/components/settings/index.vue'
import Users, {
  userRoutes
} from '@/components/users/index.vue'
import Login, {
  loginRoutes
} from '@/components/login/index.vue'
import Store from '@/store/index.js'
Vue.use(VueRouter)


let Router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/dashboard",
      component: Dashboard,
      meta: {
        menu: "main",
        requiresAuth: true,
        icon: "monitor-dashboard",
        name: "Dashboard"
      }
    },
    {
      path: "/customers",
      component: Customers,
      meta: {
        menu: "main",
        requiresAuth: true,
        icon: "contact_mail",
        name: "Kunden"
      }
    },
    {
      path: "/consignments",
      component: Consignments,
      children: consignmentRoutes,
      meta: {
        menu: "main",
        requiresAuth: true,
        icon: "add_shopping_cart",
        name: "Ankauf"
      }
    },
    {
      path: "/stocks",

      component: Stocks,
      children: stockRoutes,
      meta: {
        menu: "main",
        requiresAuth: true,
        icon: "package-variant-closed",
        name: "Lagerbest."
      }
    },

    {
      path: "/shop",

      component: Shop,
      children: shopRoutes,
      meta: {
        menu: "main",
        requiresAuth: true,
        icon: "shopping_basket",
        name: "Shop"
      }
    },

    {
      path: "/invoices",
      component: Invoices,
      children: invoiceRoutes,
      meta: {
        menu: "main",
        requiresAuth: true,
        icon: "document-check",
        name: "Rechnungen"
      }
    },
    {
      path: "/data",
      component: Data,
      children: dataRoutes,
      meta: {
        menu: "main",
        requiresAuth: true,
        icon: "bottles",
        name: "Stammdaten"
      }
    },
    {
      path: "/pages",

      component: Pages,
      children: pagesRoutes,
      meta: {
        menu: "main",
        requiresAuth: true,
        icon: "paragraph",
        name: "Seiten+FAQ",
        wa: true,
        rp: false
      }
    },

    {
      path: "/users",

      component: Users,
      children: userRoutes,
      meta: {
        menu: "main",
        requiresAuth: true,
        icon: "grouppeople",
        name: "Benutzer"
      }
    },
    {
      path: "/settings",

      component: Settings,
      meta: {
        menu: "none",
        requiresAuth: true,
        icon: "settings",
        name: "Einstellungen",
        wa: true,
        rp: true
      }
    },
    {
      path: "/login",
      component: Login,
      children: loginRoutes,
      meta: {
        menu: "none",
        requiresAuth: false,
        icon: "account-check-outline"
      }
    }
  ]
})

Router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  //console.log('Orig-Router-Ziel ' + to.path + ' (from ' + from.path + ', next ' + next.path + ')')
  if (to.path == from.path) {
    //console.log('Gleiche Adresse')
    return next();
  }
  const created = Store.getters.created


  //App initialisiert
  if (created) {
    const publicPages = Store.getters.config.urlsWithoutLogin;
    const authRequired = to.meta.requiresAuth;
    const isLoggedIn = Store.getters.isLoggedIn
    //console.log('Router-Ziel ' + to.path + ' (from ' + from.path + ', next ' + next.path + ')')
    //console.log('Login-Status im Routers ' + isLoggedIn)
    //console.log('Authrequired ' + authRequired)

    //Nicht eingeloogt und public page
    if (!authRequired && !isLoggedIn) {

      return next();
    }

    //Nicht eingeloggt und keine public Page
    if (authRequired && !isLoggedIn) {
      //console.log('Nicht eingeloggt und keine Public Page. Leite auf login um')
      return next({
        path: '/login'
      })
    }

    var ts = new Date();
    ts = Math.floor((ts.getTime() / 1000)) - Store.getters.jwtToken.tsDiff;
    //console.log(ts + ' Korrigierte -Zeit auf rechner')
    //console.log(Store.getters.jwtToken.expiresAt + ' Tokenablauf')
    //console.log(Store.getters.jwtToken.refreshAfter + ' Tokenerneuer')
    //console.log(ts + ' UTC Zeit auf Rechner')
    //Token abgelaufen



    if (Store.getters.jwtToken.expiresAt < ts) {
      //console.log('Token abgelaufen');
      Store.commit('logout');
      next('/login');
    }




    //Neues Token anfordern
    else if (Store.getters.jwtToken.expiresAt > ts && Store.getters.jwtToken.refreshAfter <= ts) {
      //console.log('Neues Token nötig');
      Store.commit('token_refresh_requested', true);
    }

    var module = to.path.split('/')[1].toLowerCase();
    const permissions = Store.getters.permissions
    //console.log('Modul ' + module)

    //Definierte Seite und rechte vorhanden
    if (typeof permissions[module] != 'undefined' && permissions[module] == 1) {
      return next();
    }


    //TODO Meldung wenn keine Rechte bestehen
    if (typeof permissions[module] != 'number' || permissions[module] != 1) {
      //console.log('Keine Rechte oder unbekannte Seite ' + to.path);
      //console.log(permissions);
      var newTo = '/dashboard';
      if (typeof permissions['dashboard'] != 'number' || permissions['dashboard'] != 1) {
        for (let key in permissions) {
          if (permissions.hasOwnProperty(key)) {
            //console.log(`${key} : ${permissions[key]}`)
            if (permissions[key] == 1) {
              newTo = '/' + key;
              break;
            }
          }
        }
        //console.log('Neues Ziel ' + newTo);
      }
      return next(newTo);
    }
    return next();
  }
})

Router.afterEach((to, from) => {
  window.scrollTo(0, 0);
})
export default Router