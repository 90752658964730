<template>
  <div>
    <ex4-headline />

    <transition name="contenttrans" mode="out-in" appear>
      <section>
        <b-tabs type="is-boxed" @input="changeTab" v-model="currentTab" destroy-on-hide>
          <template v-for="tab in tabs">
            <b-tab-item :key="tab.key" :value="tab.key" :label="tab.name"> </b-tab-item>
          </template>
        </b-tabs>
        <router-view />
      </section>
    </transition>
  </div>
</template>

<script>
import Ex4Headline from "../_helpers/headline.vue";

const ShopOrders = (resolve) => {
  require.ensure(
    ["./orders.vue"],
    () => {
      resolve(require("./orders.vue"));
    },
    "shop"
  );
};

const ShopShipping = (resolve) => {
  require.ensure(
    ["./shipping.vue"],
    () => {
      resolve(require("./shipping.vue"));
    },
    "shop"
  );
};

const ShopLivex = (resolve) => {
  require.ensure(
    ["./livex.vue"],
    () => {
      resolve(require("./livex.vue"));
    },
    "shop"
  );
};

const ShopDiscounts = (resolve) => {
  require.ensure(
    ["./discounts.vue"],
    () => {
      resolve(require("./discounts.vue"));
    },
    "shop"
  );
};

export const shopRoutes = [
  {
    path: "",
    redirect: "orders",
  },
  {
    path: "orders",
    name: "shopOrders",
    component: ShopOrders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "shipping",
    name: "shopShipping",
    component: ShopShipping,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "livex",
    name: "shopLivex",
    component: ShopLivex,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "discountcodes",
    name: "shopDiscounts",
    component: ShopDiscounts,
    meta: {
      requiresAuth: true,
    },
  },
];

export default {
  name: "Data",
  components: {
    ShopOrders,
    ShopShipping,
    ShopLivex,
    ShopDiscounts,
    Ex4Headline,
  },
  data() {
    return {
      searchphrase: "",
      currentTab: "wines",
      tabs: [
        { key: "orders", name: "Bestellungen" },
        { key: "livex", name: "Liv-ex" },
        { key: "shipping", name: "Versandkosten" },
        { key: "discountcodes", name: "Gutscheincodes" },
      ],
    };
  },

  created: function () {
    document.querySelector("title").innerHTML = "Online-Shop | SG Fine Wine";
    this.$store.commit("setHeadline", {
      headline: "Online-Shop",
      subheadline: "",
      search: false,
      add: false,
      submenu: [],
      icon: "shopping_basket",
    });

    var currentRoute = this.$router.currentRoute.fullPath.split("/");
    if (currentRoute[2].length > 2) {
      for (var i = 0; i <= this.tabs.length; i++) {
        if (this.tabs[i].key == currentRoute[2]) {
          this.currentTab = currentRoute[2];
          break;
        }
      }
    }
  },
  methods: {
    changeTab(e) {
      console.log(e);
      this.$router
        .push({
          path: "/shop/" + e,
        })
        .catch((err) => {
          console.log("fehler", err);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
