<template>
  <div class="columns is-centered" style="margin-top: 30px;">
    <div class="column is-3 is-dektop">
      <transition name="moduletrans" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Password from './password.vue'
import Loginform from './loginform.vue'
import NewPassword from './newpassword.vue'

export const loginRoutes = [
  {
    path: '',
    name: 'loginform',
    component: Loginform,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'password',
    name: 'password',
    component: Password,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'npw/:code',
    name: 'npw',
    component: NewPassword,
    meta: {
      requiresAuth: false
    }
  }
]

export default {
  components: {
    Password,
    Loginform
  }
}
</script>

<style scoped>
div.card {
  position: relative;
}
section.container {
  min-height: 500px !important;
}
</style>
