<template>
  <footer>
    <div class="main">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h3><b-icon icon="info" size="is-medium"></b-icon></h3>
            {{ company }}<br />
            {{ cityline }}
          </div>
          <div class="column">
            <h3><b-icon icon="live_help" size="is-medium"></b-icon></h3>
            <a :href="supportEmail">E-Mail Techn. Support</a><br />
            <a @click.prevent="reloadSPA()">Oberfläche (SPA) neu laden</a>
          </div>
          <div class="column">
            <h3><b-icon icon="account_box" size="is-medium"></b-icon></h3>
            Angemeldeter Benutzer<br />
            {{ username }}
          </div>
          <div class="column">
            <h3><b-icon icon="laptop_mac" size="is-medium"></b-icon></h3>
            Browser <span v-html="browser"></span><br />
            Screen {{ screen }}
          </div>
        </div>
      </div>
    </div>
    <div class="copy">
      <div class="container">
        <img :src="pageIcon" alt="" id="footer-icon" />
        <p>&copy; {{ year }} {{ copyright }} | System v{{ version }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      cityline: '',
      version: '',
      copyright: '',
      supportEmail: '',
      supportTel: '',
      year: 0,
      browser: 'Browser',
      screen: '',
    }
  },
  computed: {
    username: function() {
      var u = this.$store.getters.user
      return '#' + u.id + ' | ' + u.firstname + ' ' + u.lastname
      

    },
    company: function(){
      return this.$store.getters.config.owner.company;
    },

    
    pageIcon: function(){
      return this.$store.getters.config.page.icon
    }
  
    
  },

  created: function() {
    this.cityline = this.$store.getters.config.owner.zip + ' ' + this.$store.getters.config.owner.city
    this.version = this.$store.getters.config.development.version
    this.copyright = this.$store.getters.config.development.company
    this.supportEmail = 'mailto:' + this.$store.getters.config.development.supportEmail
    this.supportTel = 'tel:' + this.$store.getters.config.development.supportTel
    this.year = new Date().getYear() + 1900
    this.browser = this.getBrowser()
    this.screen = window.screen.availWidth + ' x ' + window.screen.availHeight
  },

  methods: {
    getBrowser() {
      var ua = navigator.userAgent,
        tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return 'IE ' + (tem[1] || '')
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
        if (tem != null)
          return tem
            .slice(1)
            .join(' ')
            .replace('OPR', 'Opera')
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
      return M.join(' ')
    },
    reloadSPA(){
      
      //console.log(this.$router.currentRoute.fullPath)
      window.location =  this.$router.currentRoute.fullPath+ '?' +Date.now();
    
    }
  }
}
</script>

<style scoped lang="scss">
footer {
  border-top: 1px solid #424242;
  border-bottom: 8px solid #3d4c99;
  width: 100%;
  background-color: #e0e3ef;;
  color: #989daa;
  font-size: 13px;
  box-sizing: border-box;
  text-align: center;
}

footer a {
  color: #989daa;
}
footer a:hover {
  color: #3d4c99;
}

footer div.main {
  padding-top: 40px;
  padding-bottom: 40px;
}

footer h3 {
  line-height: 24px;
  height: 24px;
  margin-bottom: 10px;
}

footer h3 span.icon {
  float: none;
  clear: both;
}

footer #footer-icon {
  width: 40px;
  margin: 40px;
}

footer div.copy {
  padding: 10px;
  background-color: #989daa;
  color: #e0e3ef;;
}
</style>
