<template>
  <div class="card" style="margin: 20px;" ref="loginform" :is-full-page="false">
    <form method="post" class="card-content loginform" @submit.prevent="newpassword1">
      <img src="/img/logo_gold_dunkelgrau.svg" style="padding: 20px;" />

      <b-notification :type="type" v-if="message" :message="message" />

      <p>
        <b>Passwort vergessen?</b><br />
        Bitte geben Sie Ihre E-Mail-Adresse ein!
      </p>

      <b-input
        v-model.trim="$v.u.$model"
        placeholder="E-Mail"
        type="text"
        icon="person"
        :class="{ 'is-danger': submitted && $v.u.$error }"
      ></b-input>
      <div class="is-error" v-if="submitted && $v.u.$error">Bitte geben Sie eine gültige E-Mail-Adresse an!</div>

      <b-button type="is-info" native-type="submit" id="loginbutton"
        ><b-icon icon="arrow-right"></b-icon> Weiter</b-button
      >
      <hr />
      <router-link to="/login" no-prefetch class="is-size-7 icon-link">
        <b-icon icon="arrow-left" style="float: none;" /><span class="text">Zurück zum Login</span>
      </router-link>
    </form>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      u: '',
      message: '',
      error: null,
      isLoading: false,
      submitted: false
    }
  },
  computed: {
    type: function() {
      if (this.error == true) {
        return 'is-danger'
      } else if (this.error == false) {
        return 'is-success'
      }
      return 'is-info'
    }
  },
  validations: {
    u: {
      required,
      email
    }
  },

  methods: {
    async newpassword1() {
      this.message = ''
      this.submitted = true

      this.$v.$touch()
      if (this.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        //Ladeanimation anzeigen
        this.isLoading = true
        this.error = null
        let email = this.u
        var s = this
        this.$http
          .post('/login/newpassword1', { email })
          .then(function(resp) {
            if (resp.data.code == 200) {
              s.error = false
            } else {
              s.error = true
            }
            s.message = resp.data.message
            s.isLoading = false

            return true
          })
          .catch(function(error) {
            //console.log('Shithappens')
            s.error = true
            if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
              s.message = error.response.data.message
            } else {
              s.message = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
            }
            s.isLoading = false
          })
      }
    }
  }
}
</script>

<style scoped>
form.loginform {
  text-align: center;
}

form.loginform a {
  display: inline-block;
}

form.loginform a span.text {
  line-height: 12px;
  display: block;
  visibility: hidden;
}
form.loginform a:hover span.text {
  visibility: visible;
}
form.loginform div.notification {
  font-size: 14px;
  padding: 5px;
}

button {
  margin-top: 20px;
}

#loginbutton span {
  margin-right: 5px;
}
</style>
