<template>
  <div class="card" style="margin: 20px;" ref="loginform" :is-full-page="false">
    <form method="post" class="card-content loginform" @submit.prevent="newpassword2">
      <img src="/img/logo_gold_dunkelgrau.svg" style="padding: 20px;" />
      <Notification :message="error" v-if="error" />
      <p>
        Neues Passwort:
      </p>
      <b-input
        v-model.trim="$v.p1.$model"
        placeholder="Passwort"
        :class="{ is_danger: $v.p1.$error }"
        icon="vpn_key"
        type="password"
      >
      </b-input>
      <div class="is-error" v-if="submitted && $v.p1.$error">
        Das Passwort muss mindestens 10 Zeichen lang sein, Klein- und Großbuchstaben, Ziffern und mindestens ein
        Sonderzeichen #+*$§%! enthalten
      </div>

      <p>
        Passwort wiederholen:
      </p>
      <b-input
        v-model.trim="$v.p2.$model"
        placeholder="Passwort wiederholen"
        :class="{ is_danger: $v.p1.$error }"
        icon="vpn_key"
        type="password"
      >
      </b-input>
      <div class="is-error" v-if="submitted && $v.p2.$error">
        Die Passwörter stimmen nicht überein!
      </div>

      <b-button type="is-info" native-type="submit" id="loginbutton" 
        ><b-icon icon="cloud_upload"></b-icon> Speichern</b-button
      >
      <hr />
      <router-link to="/login" no-prefetch class="is-size-7 icon-link">
        <b-icon icon="login" style="float: none;" /><span class="text">Zum Login</span>
      </router-link>
    </form>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>

<script>

import { required, minLength, helpers, sameAs } from 'vuelidate/lib/validators'
const valNumbers = (value) => /[0-9]+/.test(value)
const valUpperCase = (value) => /[A-Z]+/.test(value)
const valLowerCase = (value) => /[a-z]+/.test(value)
const valSpecialChars = (value) => /[#+*$§%!]+/.test(value)


export default {
  data() {
    return {
      p1: '',
      p2: '',
      error: null,
      isLoading: false,
      submitted: false
    }
  },

  validations: {
    p1: {
      required,
      minLength: minLength(10),
      valNumbers,
      valUpperCase,
      valSpecialChars
    },
    p2: {
      required,
      minLength: minLength(10),
      sameAsPassword: sameAs('p1')
    }
  },

  methods: {
    async newpassword2() {
      //Ladeanimation anzeigen
      
      this.error = null
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        this.isLoading = true
        var s = this
        this.$http
          .post('/login/newpassword2', { p1: s.p1, p2: s.p2, key: s.$route.params.code })
          .then(function(resp) {
            //console.log('Schließe Loading')
            s.isLoading = false
            //console.log(resp.data)
            return true
          })
          .catch(function(error) {
            //console.log('Shithappens')
            if (typeof error.response != 'undefined' && typeof error.response.data.message != 'undefined') {
              s.error = error.response.data.message
            } else {
              s.error = 'Server nicht erreichbar. Bitte versuchen Sie es erneut.'
            }
            s.isLoading = false
          })
      }
    }
  }
}
</script>

<style scoped>
form.loginform {
  text-align: center;
}
form.loginform div,
control input.input {
  margin-bottom: 20px;
}

form.loginform a {
  display: inline-block;
}

form.loginform a span.text {
  line-height: 12px;
  display: block;
  visibility: hidden;
}
form.loginform a:hover span.text {
  visibility: visible;
}
form.loginform div.notification {
  font-size: 14px;
  padding: 5px;
}

#loginbutton span {
  margin-right: 5px;
}
</style>
